import cx from 'classnames';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';

import { styles } from './FilterCounter.styles';

export interface FilterCounterProps extends WithStyles<typeof styles> {
  count: number;
}

const FilterCounterComponent: React.FC<FilterCounterProps> = ({ classes, count }) => {
  return (
    <Flex
      autoWidth={false}
      alignItems="center"
      justifyContent="center"
      className={cx(classes.root, { [classes.rootZero]: count == 0 })}
    >
      <p className={cx(classes.count, { [classes.countZero]: count == 0 })}>{count}</p>
    </Flex>
  );
};

export const FilterCounter = withStyles(styles)(FilterCounterComponent);
