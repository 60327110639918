import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ButtonProps } from '@shared/components/button-new';
import { Dialog } from '@shared/components/dialog';

import styles from './Confirmation.styles';

export type ButtonsProps = {
  confirm: Partial<ButtonProps>;
  cancel: Partial<ButtonProps>;
};

export interface ConfirmationProps extends WithStyles<typeof styles> {
  buttonProps?: ButtonsProps;
}

let showConfirmationFn: any;

export function showConfirmation(
  {
    withCloseButton,
    title,
    question,
    buttonProps,
  }: {
    withCloseButton?: boolean;
    title: ReactNode;
    question: ReactNode;
    buttonProps?: ButtonsProps;
  },
  callback: (isConfirmed: boolean) => void
): void {
  showConfirmationFn({ title, question, buttonProps, withCloseButton }, callback);
}

interface ConfirmationState {
  open: boolean;
  withCloseButton?: boolean;
  title: React.ReactNode;
  question: React.ReactNode;
  buttonProps: ButtonsProps;
  callback?: (isConfirmed?: boolean) => void;
}

const DEFAULT_STATE: ConfirmationState = {
  open: false,
  withCloseButton: true,
  title: '',
  question: '',
  buttonProps: {
    confirm: {
      text: t('continue'),
    },
    cancel: {
      text: t('cancel'),
    },
  },
  callback: undefined,
};

const ConfirmationComponent: FC<ConfirmationProps> = ({ classes }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    showConfirmationFn = show;
  }, []);

  const show = useCallback(({ title, question, buttonProps, withCloseButton }, callback) => {
    setState((prevState) => ({
      ...prevState,
      title,
      question,
      callback,
      withCloseButton,
      open: true,
      buttonProps: { ...prevState.buttonProps, ...buttonProps },
    }));
  }, []);

  const terminate = useCallback(() => {
    if (state.callback) {
      state.callback(false);
    }
    hide();
  }, [state]);

  const hide = useCallback(() => {
    setState((prevState) => ({ ...prevState, open: false }));
    setTimeout(() => setState(DEFAULT_STATE), 500);
  }, []);

  const handleYes = useCallback(() => {
    if (state.callback) {
      state.callback(true);
    }
    hide();
  }, [state]);

  const { open, title, question, buttonProps } = state;

  return (
    <Dialog
      buttonProps={{
        cancel: { ...buttonProps.cancel },
        confirm: { ...buttonProps.confirm },
      }}
      classes={{ root: classes.root, body: classes.content }}
      headingProps={{
        heading: title,
        withCloseButton: false,
      }}
      open={open}
      withTransition
      onClose={terminate}
      onSubmit={handleYes}
    >
      <>{question}</>
    </Dialog>
  );
};

export const Confirmation = withStyles(styles)(ConfirmationComponent);
