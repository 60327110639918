export enum Societies {
  WEAS = 1,
  CAC = 2,
  OAS = 3,
  IAS = 4,
  SAS = 5,
  RLAS = 6,
}

export interface Society {
  name: string;
  displayName: string;
  abbreviation: string;
}

export type SocietiesHash = Record<Societies, Society>;

export const societiesHash: SocietiesHash = {
  [Societies.WEAS]: { name: 'West End Agents Society', displayName: 'West End Agents', abbreviation: 'WEAS' },
  [Societies.CAC]: { name: 'City Agents Club', displayName: 'City Agents Club', abbreviation: 'CAC' },
  [Societies.OAS]: { name: 'Office Agents Society', displayName: 'OAS', abbreviation: 'OAS' },
  [Societies.IAS]: { name: 'Industrial Agents Society', displayName: 'IAS', abbreviation: 'IAS' },
  [Societies.SAS]: { name: 'Shop Agents Society', displayName: 'SAS', abbreviation: 'SAS' },
  [Societies.RLAS]: { name: 'Retail & Leisure Agents Society', displayName: 'RLAS', abbreviation: 'RLAS' },
};

export interface PrimarySocietyGroup {
  id: number;
  society: Societies;
  filterByGroup: boolean;
}
