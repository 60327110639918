import cx from 'classnames';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Badge, BADGE_SIZE, BadgeProps } from '@shared/components/badge';
import { Flex } from '@shared/components/flex';
import { Link } from '@shared/components/link';
import { ZapIcon } from '@shared/icons/zap';
import { checkIsActive } from '@shared/utils/common';

import { styles } from './SideNavigationItem.styles';

export interface SideNavigationItemProps extends WithStyles<typeof styles> {
  badge?: Omit<BadgeProps, 'classes'>;
  startIcon: ReactNode;
  to: string;
  title: string;
}

const SideNavigationItemComponent: FC<SideNavigationItemProps> = ({ badge, classes, startIcon, to, title }) => {
  const { pathname } = useLocation();

  return (
    <Link
      classes={{
        root: classes.link,
        rootExternal: classes.externalLink,
        rootInternal: cx({ [classes.linkActive]: checkIsActive(pathname, to) }),
      }}
      to={to}
    >
      <Flex autoWidth={false} wrap="nowrap" classes={{ root: classes.linkContainer }}>
        <span className={classes.icon}>{startIcon}</span>
        <span className={classes.title}>{title}</span>
        {!!badge && (
          <Badge
            size={BADGE_SIZE.small}
            startIcon={<ZapIcon classes={{ root: classes.badgeIcon }} />}
            {...badge}
            classes={{ badge: classes.badge }}
          />
        )}
      </Flex>
    </Link>
  );
};

export const SideNavigationItem = withStyles(styles)(SideNavigationItemComponent);
