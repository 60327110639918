import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    content: {},
    message: {
      color: '#515D70', //TODO replace after creating new palette
      fontWeight: 400,
    },
    progressWrapper: {
      width: '100%',
      marginTop: spacing(3),
    },
    progress: {
      borderRadius: 6,
    },
  });
