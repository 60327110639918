import hotjar from '@hotjar/browser';
import { useEffect } from 'react';

interface HotjarInitOptions {
  hotjarId: number;
}

export const useHotjar = ({ hotjarId }: HotjarInitOptions) => {
  useEffect(() => {
    if (hotjarId) {
      hotjar.init(hotjarId, 6);
    }
  }, []);
};
