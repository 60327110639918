import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { $http, useQuery, UseQueryOptions } from '@core/http-client';
import { ConfigAgency, ConfigLandlord } from '@shared/models/config';

import { ConfigErrorResponse } from './configApi.types';

export const configQueryKeys = createQueryKeys('config', {
  all: null,
  showAgency: () => ({
    queryKey: [''],
    queryFn: async () => (await $http.get<ConfigAgency>(`/config`)).data,
  }),
  showLandlord: () => ({
    queryKey: [''],
    queryFn: async () => (await $http.get<ConfigLandlord>(`/config-landlord`)).data,
  }),
});
export type ConfigQueryKeys = inferQueryKeys<typeof configQueryKeys>;

export const useConfigAgencyFetchQuery = (options?: UseQueryOptions<ConfigAgency, ConfigErrorResponse>) =>
  useQuery<ConfigQueryKeys['showAgency']['queryKey'], ConfigAgency, ConfigErrorResponse>({
    ...configQueryKeys.showAgency(),
    keepPreviousData: true,
    ...options,
  });

export const useConfigLandlordFetchQuery = (options?: UseQueryOptions<ConfigLandlord, ConfigErrorResponse>) =>
  useQuery<ConfigQueryKeys['showLandlord']['queryKey'], ConfigLandlord, ConfigErrorResponse>({
    ...configQueryKeys.showLandlord(),
    keepPreviousData: true,
    ...options,
  });
