import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';
import cx from 'classnames';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Tooltip.styles';

export type TooltipRef = HTMLDivElement;

export interface TooltipProps extends Omit<MuiTooltipProps, 'classes'>, WithStyles<typeof styles> {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const TooltipComponent: ForwardRefRenderFunction<TooltipRef, TooltipProps> = (
  { arrow = true, children, classes, containerProps, onClick, placement, title, ...otherProps },
  ref
) => {
  return (
    <MuiTooltip
      {...otherProps}
      arrow={arrow}
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      className={classes.root}
      placement={placement}
      ref={ref}
      title={title}
    >
      <div {...containerProps} className={cx(containerProps?.className, classes.container)}>
        {children}
      </div>
    </MuiTooltip>
  );
};

export const Tooltip = withStyles(styles)(forwardRef(TooltipComponent));
