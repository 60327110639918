import { CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, TooltipProps } from '@material-ui/core';

import { Tooltip } from '@shared/components/tooltip';

export interface ButtonProps extends MuiButtonProps {
  component?: React.ElementType;
  loading?: boolean;
  text?: React.ReactNode;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

const ButtonComponent: React.FC<ButtonProps> = ({
  loading,
  text,
  tooltip,
  tooltipPlacement = 'left',
  children,
  color = 'primary',
  ...otherProps
}) => {
  const baseProps: MuiButtonProps = {
    color,
    variant: 'contained',
    size: 'small',
    disabled: loading,
    ...otherProps,
  };

  const spinner = loading ? (
    <CircularProgress
      color={baseProps.color === 'primary' ? 'secondary' : 'primary'}
      size={15}
      style={{ marginLeft: 8 }}
    />
  ) : null;

  const content = (
    <MuiButton {...baseProps} disabled={baseProps.disabled || loading}>
      {text || children} {spinner}
    </MuiButton>
  );

  if (tooltip) {
    return (
      <Tooltip placement={tooltipPlacement || undefined} title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Button = ButtonComponent;
