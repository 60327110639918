import { alpha, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderRadius: 6,
      color: colors.white.lightest,
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: 14,
      height: 40,
      justifyContent: 'space-between',
      margin: 0,
      outline: `1px solid ${alpha(colors.white.lightest, 0.2)}`,
      padding: spacing(2.5, 2),
      transition: 'color 0.4s ease',

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        cursor: 'pointer',
        outline: 'none',
      },
    },
    badge: {
      alignItems: 'center',
      backgroundColor: palette.primarySpecial.main,
      borderRadius: 4,
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: 10,
      fontWeight: 600,
      gap: 4,
      height: 20,
      padding: spacing(0.75, 1.5),
    },
    title: {
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 700,
      overflow: 'hidden',
      position: 'relative',
      textOverflow: 'clip',
    },
    icon: {
      blockSize: 'calc(100% + 1px)',
      fontSize: 12,
    },
  });
