import { Avatar as MuiAvatar } from '@material-ui/core';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { ProBadge } from '@shared/components/pro-badge';
import { Tooltip, TooltipProps } from '@shared/components/tooltip';
import { ITheme } from '@shared/models/theme';
import { colors } from '@shared/theme/colours';
import { getInitials } from '@shared/utils/common';

import { styles } from './Avatar.styles';

export interface AvatarProps extends WithStyles<typeof styles> {
  image: string | null;
  isPro?: boolean;
  name: string;
  theme?: ITheme;
  tooltip?: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
}

const AvatarComponent: React.FC<AvatarProps> = ({
  classes,
  image,
  isPro,
  name,
  theme = { backgroundColour: null, textColour: null },
  tooltip,
  tooltipPlacement = 'top',
}) => {
  const content = (
    <>
      {image ? (
        <MuiAvatar alt="img" src={image} className={classes.avatar} />
      ) : (
        <div className={classes.noImage}>
          <Flex
            justifyContent="center"
            alignItems="center"
            className={classes.noImageInitials}
            style={{
              backgroundColor: theme.backgroundColour || colors.blue.dark,
              color: theme.textColour || colors.white.lightest,
            }}
          >
            {getInitials(name)}
          </Flex>
        </div>
      )}
      {isPro && <ProBadge classes={{ root: classes.proBadge }} />}
    </>
  );

  if (tooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        title={tooltip}
        classes={{ container: classes.root, tooltip: classes.tooltip }}
      >
        {content}
      </Tooltip>
    );
  }

  return <div className={classes.root}>{content}</div>;
};

export const Avatar = withStyles(styles)(AvatarComponent);
