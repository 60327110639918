import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    tooltip: {
      fontSize: 14,
      padding: spacing(4, 3, 3, 3),
      textAlign: 'center',
      maxWidth: 250,
      backgroundColor: colors.blue.darkest,
    },
    arrow: {
      fontSize: 14,
      '&::before': {
        backgroundColor: colors.blue.darkest,
        border: `2px solid ${colors.blue.darkest}`,
      },
    },
    container: {
      display: 'inline',
    },
  });
