import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default ({ breakpoints, spacing }: Theme) =>
  createStyles({
    root: {
      width: 400,
    },

    content: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black.darkest,
    },

    [breakpoints.down('xs')]: {
      root: {
        minWidth: 'auto',
      },
    },
  });
