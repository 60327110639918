import { withStyles, WithStyles } from '@material-ui/core/styles';
import { FC, ReactNode } from 'react';

import { Button } from '@shared/components/button';
import { Flex } from '@shared/components/flex';
import { Grid } from '@shared/components/grid';

import { styles } from './ErrorHandler.styles';

export interface ErrorHandlerProps extends WithStyles<typeof styles> {
  heading?: ReactNode;
}

const ErrorHandlerComponent: FC<ErrorHandlerProps> = ({ classes, heading = 'Something went wrong' }) => {
  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <Flex direction="column" justifyContent="center" alignItems="center" classes={{ root: classes.root }}>
      <Grid container className={classes.message} justifyContent="center" alignItems="center" direction="column">
        <h4 className={classes.heading}>{heading}</h4>
      </Grid>
      <Button text="Reload page" onClick={handleReloadPage} />
    </Flex>
  );
};

export const ErrorHandler = withStyles(styles)(ErrorHandlerComponent);
