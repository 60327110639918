import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';

let lastPage = '';

export const sendGaEvent = (pathname: string, search: string) => {
  if (!ReactGA.isInitialized) {
    throw new Error('Google analytics not initialised');
  }

  const currentPage = `${pathname}${search}`;

  if (currentPage && lastPage !== currentPage) {
    lastPage = currentPage;
    // console.log('sendGaEvent. Sending page_view event: ', currentPage);
    ReactGA.event('page_view', { page_location: currentPage });
  }
};

export const useGoogleAnalytics = (initialOptions: InitOptions[] | (() => InitOptions[])) => {
  const [options, _] = useState<InitOptions[]>(initialOptions);

  // Initialise google analytics
  useEffect(() => ReactGA.initialize(options), [options]);

  // Send initial page view
  useEffect(() => sendGaEvent(window.location.pathname, window.location.search), []);
};
