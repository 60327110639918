import { createContext, FC, useContext, useMemo } from 'react';

import { t } from '@core/i18n';
import { useConfigAll } from '@shared/contexts/ConfigContext';
import { ConfigCoreConstants } from '@shared/models/config';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { OptionsItem, stringObjectToOptions } from '@shared/utils/options';

export const getCoreOptionsValue = (constants: ConfigCoreConstants) => {
  return {
    buildingTypeOptions: stringObjectToOptions(constants.buildingTypes),
    confidentialityOptions: [
      { id: '0', label: t('not_confidential') },
      { id: '1', label: t('confidential') },
    ],
    discountReasonOptions: stringObjectToOptions(constants.matchDiscountReasonOptions),
    interestScheduleShareRequirementOptions: stringObjectToOptions(constants.interestScheduleShareRequirementOptions),
    viewingFeedbackRadioOptions: [
      { id: ViewingOutcome.Positive, label: t('positive') },
      { id: ViewingOutcome.Neutral, label: t('neutral') },
      { id: ViewingOutcome.Negative, label: t('negative') },
    ],
    viewingFeedbackMultiSelectOptions: [
      { id: ViewingOutcome.Pending, label: t('pending_feedback') },
      { id: ViewingOutcome.None, label: t('no_feedback_given') },
      { id: ViewingOutcome.Positive, label: t('positive_feedback') },
      { id: ViewingOutcome.Neutral, label: t('neutral_feedback') },
      { id: ViewingOutcome.Negative, label: t('negative_feedback') },
      { id: ViewingOutcome.Cancelled, label: t('cancelled_hyphen_no_longer_interested') },
    ],
  };
};

export type UseCoreOptionsResult = ReturnType<typeof getCoreOptionsValue>;

const OptionsContext = createContext<UseCoreOptionsResult>({} as UseCoreOptionsResult);

export const useCoreOptions = () => {
  return useContext(OptionsContext);
};

export const CoreOptionsProvider: FC = ({ children }) => {
  const { constants } = useConfigAll();

  const result = useMemo(() => getCoreOptionsValue(constants), [constants]);

  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};

export const useEmptyOption = <TOption extends OptionsItem = OptionsItem>(options: TOption[], emptyOption: TOption) => {
  return useMemo(() => [emptyOption, ...options], [options]);
};
