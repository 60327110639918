import { useEffect, useState } from 'react';

type LocalStorageCallback<TD = any> = (newValue: TD) => void;

const listeners: Map<string, Set<LocalStorageCallback>> = new Map();

// Global storage event handler
const handleStorageChange = (event: StorageEvent) => {
  if (event.key && listeners.has(event.key)) {
    const newValue = event.newValue ? JSON.parse(event.newValue) : null;
    listeners.get(event.key)?.forEach((callback) => callback(newValue));
  }
};

// Attach the global event listener once
if (typeof window !== 'undefined') {
  window.addEventListener('storage', handleStorageChange);
}

const setStorage = <TD = any>(key: string, setter: (value: TD) => TD) => {
  const value = JSON.stringify(setter(getStorage<TD>(key)));
  localStorage.setItem(key, value);

  // Manually trigger the storage event for the current window
  handleStorageChange(new StorageEvent('storage', { key, newValue: value }));
};

const getStorage = <TD = any>(key: string): TD => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const listenStorage = <TD = any>(key: string, callback: LocalStorageCallback<TD>) => {
  if (!listeners.has(key)) {
    listeners.set(key, new Set());
  }
  listeners.get(key)?.add(callback);

  // Return a function to remove the listener
  return () => {
    listeners.get(key)?.delete(callback);
    if (listeners.get(key)?.size === 0) {
      listeners.delete(key);
    }
  };
};

export const useStorageListeners = () => {
  const [_, setListeners] = useState<ReturnType<typeof listenStorage>[]>([]);

  // Cleanup listener on component unmount
  useEffect(
    () => () => {
      setListeners((prev) => {
        prev.forEach((listener) => listener());
        return [];
      });
    },
    []
  );

  return {
    get: getStorage,
    set: setStorage,

    listen: <TD = any>(key: string, callback: LocalStorageCallback<TD>) => {
      setListeners((prev) => [...prev, listenStorage<TD>(key, callback)]);
    },
  };
};
