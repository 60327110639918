import { alpha, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderRadius: 6,
      color: alpha(colors.white.lightest, 0.65),
      display: 'flex',
      flex: '1 1 auto',
      fontSize: 14,
      gap: 8,
      height: 40,
      margin: 0,
      padding: spacing(2.5, 2),
      stroke: alpha(colors.white.lightest, 0.65),
      transitionDuration: '0.4s',
      transitionProperty: 'color, stroke',
      transitionTimingFunction: 'ease',
      userSelect: 'none',
      width: '100%',

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,
        stroke: colors.white.lightest,
        cursor: 'pointer',
      },
    },
    actionLabel: {
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 500,
      overflow: 'hidden',
      position: 'relative',
      textOverflow: 'clip',
    },
    icon: {
      alignItems: 'center',
      display: 'inline-flex',
      flex: '0 0 auto',
      minWidth: 18,

      '& svg': {
        blockSize: 'calc(100% + 1px)',
        fontSize: 20,
      },
      '& svg path': {
        stroke: 'inherit',

        '&:hover': {
          stroke: 'inherit',
        },
      },
    },
  });
