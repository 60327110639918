import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  lng: 'en',
  missingKeyNoValueFallbackToKey: false,
  ns: ['core'],
  resources: {},
  returnNull: false,

  interpolation: {
    escapeValue: false,
    prefix: '[%',
    suffix: '%]',
  },
});

export const t = i18next.t;

export default i18next;
