import { Chip } from '@material-ui/core';
import cx from 'classnames';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getBaseUrl } from '@core/base-url';
import { AppId, useAppUrl, useConfig, useConfigAll } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';
import { BarChartIcon } from '@shared/icons/bar-chart-new';
import { BuildingIcon } from '@shared/icons/building-new';
import { CalendarCheckIcon } from '@shared/icons/calendar-check-new';
import { CheckCircleBrokenIcon } from '@shared/icons/check-circle-broken-new';
import { ChevronLeftIcon } from '@shared/icons/chevron-left';
import { ChevronRightIcon } from '@shared/icons/chevron-right';
import { ClipboardCheckIcon } from '@shared/icons/clipboard-check-new';
import { CoinsHandIcon } from '@shared/icons/coins-hand';
import { FileCheckIcon } from '@shared/icons/file-check';
import { FileCheck2Icon } from '@shared/icons/file-check-2';
import { HelpIcon } from '@shared/icons/help';
import { HomeIcon } from '@shared/icons/home';
import { MailIcon } from '@shared/icons/mail';
import { PinIcon } from '@shared/icons/pin-new';
import { PropertyIcon } from '@shared/icons/property';
import { SettingsIcon } from '@shared/icons/settings';
import { UserCheckIcon } from '@shared/icons/user';
import {
  AppGroupedNavItem,
  AppGroupedNavSubItem,
  AppNavAllItemKeys,
  AppNavGroupItemKeys,
  AppNavItem,
} from '@shared/models/config';
import { reduceStringRecordToObject } from '@shared/utils/common';
import { getDefaultPath, getTransformedRoute } from '@shared/utils/route';

import { SideNavigationExploreUpsellItem } from './components/explore-upsell-item';
import { AgentSocietyNavigationLogo } from './components/logo/agent-society-navigation-logo';
import { ComptonNavigationLogo } from './components/logo/compton-navigation-logo';
import { JllNavigationLogo } from './components/logo/jll-navigation-logo';
import { KatoNavigationLogo } from './components/logo/kato-navigation-logo';
import { RxNavigationLogo } from './components/logo/rx-navigation-logo';
import { SideNavigationAction } from './components/side-navigation-action';
import { SideNavigationGroup, SideNavigationGroupItem } from './components/side-navigation-group';
import { SideNavigationItem } from './components/side-navigation-item';
import { SideNavigationUserItem } from './components/side-navigation-user-item';
import { styles } from './SideNavigation.styles';
import { BADGE_THEME } from '../badge';

export type MenuOpenHash = { [key in AppNavGroupItemKeys]: boolean };
export type IconHash = { [key in AppNavAllItemKeys]: ReactNode };

interface GroupMenus {
  [key: string]: string[];
}

const iconHash: IconHash = {
  organisation_advisory: <FileCheckIcon />,
  organisation_campaigns: <MailIcon />,
  organisation_crm: <UserCheckIcon />,
  organisation_disposals: <BuildingIcon />,
  organisation_home: <HomeIcon />,
  organisation_insights: <BarChartIcon />,
  organisation_leases: <FileCheck2Icon />,
  organisation_properties: <PropertyIcon />,
  organisation_requirements: <ClipboardCheckIcon />,
  organisation_sales: <CoinsHandIcon />,
  organisation_tasks: <CheckCircleBrokenIcon />,
  organisation_viewings: <CalendarCheckIcon />,
  organisation_wip_fees: <PinIcon />,

  marketplace_dashboard: <i className="icon icon-society" />,
  marketplace_disposals: <BuildingIcon />,
  marketplace_events: <i className="icon icon-calendar" />,
  marketplace_insights: <BarChartIcon />,
  marketplace_members: <i className="icon icon-users" />,
  marketplace_preferences: <SettingsIcon />,
  marketplace_requirements: <ClipboardCheckIcon />,
};

export interface SideNavigationProps extends WithStyles<typeof styles> {
  collapsed: boolean;
  onCollapse: () => void;
}

const initialMenuState: Record<AppNavGroupItemKeys, boolean> = {
  organisation_advisory: false,
  organisation_crm: false,
  organisation_disposals: false,
  organisation_insights: false,
  organisation_leases: false,
  organisation_sales: false,
  organisation_wip_fees: false,

  marketplace_insights: false,
};

export interface SideNavigationProps extends WithStyles<typeof styles> {
  collapsed: boolean;
  onCollapse: () => void;
}

const SideNavigationComponent: FC<SideNavigationProps> = ({ classes, collapsed, onCollapse }) => {
  const { appUrl } = useAppUrl();
  const { appId } = useConfigAll();
  const { appLogos, appNav, coogan, marketingUrl, user } = useConfig();
  const { pathname } = useLocation();

  const [localCollapse, setLocalCollapse] = useState(collapsed);
  const [menu, setMenu] = useState<MenuOpenHash>(initialMenuState);

  const groupMenus: GroupMenus = useMemo(() => {
    return [...appNav.organisation_items_order, ...appNav.marketplace_items_order].reduce((result, itemKey) => {
      const navItem = appNav[itemKey] as AppGroupedNavItem;

      if (navItem && Array.isArray(navItem?.items_order) && navItem?.items_order?.length) {
        result[itemKey] = navItem.items_order.reduce((subResult, subItemKey) => {
          const subItem = navItem.items[subItemKey] as AppGroupedNavSubItem;

          if (subItem && subItem.url) {
            subResult.push(getTransformedRoute(appUrl, subItem.url));
          }

          return subResult;
        }, [] as string[]);
      }

      return result;
    }, {} as GroupMenus);
  }, [appNav, appUrl]);

  useEffect(() => {
    const _menuState: MenuOpenHash = reduceStringRecordToObject(groupMenus, (result, _, key) => {
      result[key as AppNavGroupItemKeys] = groupMenus[key]?.includes(pathname) ?? false;
      return result;
    });
    setMenu(_menuState);
  }, [groupMenus, pathname]);

  const renderItems = useCallback(
    (itemKeys: AppNavAllItemKeys[]): ReactNode => {
      return itemKeys.reduce((result, itemKey) => {
        // Check if visible
        if (!appNav[itemKey] || !appNav[itemKey].visible) {
          return result;
        }

        if (appNav[itemKey] && 'items_order' in appNav[itemKey]) {
          const groupedNavItem = appNav[itemKey] as AppGroupedNavItem;
          const menuKey = itemKey as AppNavGroupItemKeys;

          const subItems = groupedNavItem.items_order.reduce((subResult, subItemKey) => {
            if (!(subItemKey in groupedNavItem.items)) {
              return subResult;
            }

            const subItem = groupedNavItem.items[subItemKey] as AppGroupedNavSubItem;

            if (subItem.url && subItem.visible) {
              // Prioritise the short label since it'll be shown in grouped item
              subResult.push({
                title: subItem.label_short || subItem.label,
                to: subItem.url,
              });
            }

            return subResult;
          }, [] as SideNavigationGroupItem[]);

          // Only add group item if we have items
          if (subItems.length) {
            result.push(
              <SideNavigationGroup
                key={itemKey}
                expanded={menu[menuKey]}
                icon={iconHash[itemKey]}
                items={subItems}
                title={groupedNavItem.label}
                setExpanded={() => setMenu((_prev) => ({ ...initialMenuState, [itemKey]: !_prev[menuKey] }))}
                badge={
                  appNav[itemKey].badge_label
                    ? { theme: BADGE_THEME.danger, text: appNav[itemKey].badge_label }
                    : undefined
                }
              />
            );
          }
        } else {
          const navItem = appNav[itemKey] as AppNavItem;

          // Add non-grouped item
          if (navItem.url) {
            result.push(
              <SideNavigationItem
                key={itemKey}
                startIcon={iconHash[itemKey]}
                to={navItem.url}
                title={navItem.label}
                badge={navItem.badge_label ? { theme: BADGE_THEME.danger, text: navItem.badge_label } : undefined}
              />
            );
          }
        }

        return result;
      }, [] as ReactNode[]);
    },
    [appNav, appUrl, iconHash, menu]
  );

  const renderFixedGroupItems = useCallback(
    (itemKeys: AppNavAllItemKeys[], icon: ReactNode, title: string): ReactNode => {
      const items = itemKeys.reduce((result, itemKey) => {
        // Check if visible
        if (!appNav[itemKey] || !appNav[itemKey].visible) {
          return result;
        }

        if (appNav[itemKey] && 'items_order' in appNav[itemKey]) {
          const groupedNavItem = appNav[itemKey] as AppGroupedNavItem;

          groupedNavItem.items_order.forEach((subItemKey) => {
            const subItem = groupedNavItem.items[subItemKey] as AppGroupedNavSubItem;

            if (subItem.visible && subItem.url) {
              result.push({ title: subItem.label, to: subItem.url });
            }
          });
        } else {
          const navItem = appNav[itemKey] as AppNavItem;

          // Add non-grouped item
          if (navItem.url && navItem.visible) {
            result.push({
              to: navItem.url,
              title: navItem.label,
              badge: navItem.badge_label ? { theme: BADGE_THEME.danger, text: navItem.badge_label } : undefined,
            });
          }
        }

        return result;
      }, [] as SideNavigationGroupItem[]);

      return <SideNavigationGroup key={'itemKey'} fixed icon={icon} items={items} title={title} />;
    },
    [appNav, appUrl]
  );

  useEffect(() => {
    setLocalCollapse(collapsed);
  }, [collapsed]);

  const logo = useMemo(() => {
    const defaultPath = getDefaultPath(appUrl, appNav);

    // Only show the kato logo for landlord
    if (appId === AppId.Landlord) {
      return <KatoNavigationLogo url={defaultPath} />;
    }

    switch (appLogos.large_id) {
      case 'society-large': {
        return <AgentSocietyNavigationLogo katoUrl={marketingUrl} url={defaultPath} />;
      }

      case 'jll-large': {
        return <JllNavigationLogo appLogos={appLogos} katoUrl={marketingUrl} url={defaultPath} />;
      }

      case 'compton-large': {
        return <ComptonNavigationLogo appLogos={appLogos} katoUrl={marketingUrl} url={defaultPath} />;
      }

      case 'rx-large': {
        return <RxNavigationLogo appLogos={appLogos} katoUrl={marketingUrl} url={defaultPath} />;
      }

      case 'insight-large':
      default: {
        return <KatoNavigationLogo url={defaultPath} />;
      }
    }
  }, [appId, appLogos, marketingUrl]);

  return (
    <div
      className={cx(classes.root, { [classes.rootCollapsed]: localCollapse })}
      onMouseEnter={() => {
        if (collapsed) {
          setLocalCollapse(false);
        }
      }}
      onMouseLeave={() => {
        if (collapsed) {
          setLocalCollapse(true);
        }
      }}
    >
      <Chip
        clickable
        onClick={onCollapse}
        classes={{
          root: cx(classes.collapseBtn, { [classes.collapseBtnVisible]: collapsed }),
        }}
        icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      />
      {coogan && (
        <Tooltip
          title={`Impersonating: ${user.name} (${user.organisation?.name})`}
          arrow={true}
          placement="right"
          classes={{
            root: classes.impersonateContainer,
            tooltip: classes.impersonateTooltip,
            arrow: classes.impersonateTooltipArrow,
          }}
        >
          <a href={`${getBaseUrl()}impersonate/leave`} className={classes.impersonateLink} />
        </Tooltip>
      )}
      <div className={cx(classes.header, { [classes.hiddenContent]: localCollapse })}>{logo}</div>
      <div className={cx(classes.contentContainer, { [classes.hiddenContent]: localCollapse })}>
        <div className={classes.content}>
          {appNav.organisation && renderItems(appNav.organisation_items_order)}
          {appNav.upgrade_cta && <SideNavigationExploreUpsellItem classes={{ root: classes.upgradeCta }} />}
          {appNav.marketplace && <div className={classes.marketplaceSectionContainer} />}
          {appNav.marketplace && !appNav.marketplace_fixed_group && (
            <div className={classes.marketplaceSectionLabel}>{t('marketplace')}</div>
          )}
          {appNav.marketplace && !appNav.marketplace_fixed_group && renderItems(appNav.marketplace_items_order)}
          {appNav.marketplace &&
            appNav.marketplace_fixed_group &&
            renderFixedGroupItems(
              appNav.marketplace_items_order,
              <i className="icon icon-society" />,
              t('marketplace')
            )}
        </div>
      </div>
      <div className={cx(classes.footer, { [classes.hiddenContent]: localCollapse })}>
        <div className={classes.footerDivider} />
        <SideNavigationAction label={t('help')} linkId="c2a-help" startIcon={<HelpIcon />} />
        <SideNavigationUserItem appNav={appNav} user={user} />
      </div>
      {!collapsed && <div className={classes.collapseLine} onClick={onCollapse} />}
    </div>
  );
};

export const SideNavigation = withStyles(styles)(SideNavigationComponent);
