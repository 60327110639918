import { FC } from 'react';

import { useConfig } from '@core/contexts/ConfigContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './IntercomLink.styles';

export interface IntercomLinkProps extends WithStyles<typeof styles> {
  linkId: string;
}

const IntercomLinkComponent: FC<IntercomLinkProps> = ({ children, classes, linkId }) => {
  const { intercomEnabled, enquireUrl } = useConfig();

  if (intercomEnabled) {
    return (
      <a href="#" id={linkId} className={classes.root}>
        {children}
      </a>
    );
  }

  return (
    <a className={classes.root} href={enquireUrl} target="_blank">
      {children}
    </a>
  );
};

export const IntercomLink = withStyles(styles)(IntercomLinkComponent);
