import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Toast, ToastProps } from '@shared/components/toast';
import { AlertRoundIcon } from '@shared/icons/alert-round';
import { CheckCircleIcon } from '@shared/icons/check-circle';
import { BulkActionCompletedPayload } from '@shared/types/common/events';

import { styles } from './BulkActionCompletedToast.styles';

export interface BulkActionCompletedToastProps
  extends Omit<ToastProps, 'classes'>,
    Omit<BulkActionCompletedPayload, 'job_id' | 'type'>,
    WithStyles<typeof styles> {
  onClickLink: (link: string) => void;
}

const BulkActionCompletedToastComponent: FC<BulkActionCompletedToastProps> = ({
  classes,
  failed_text,
  subtext,
  success_text,
  title,
  url,
  url_text,
  onClickLink,
  onClose,
}) => {
  return (
    <Toast classes={{ root: classes.root }} title={title} onClose={onClose}>
      <Flex autoWidth={false} className={classes.content} direction="column">
        <span className={classes.message}>{subtext}</span>
        <Flex autoWidth={false} direction="column" className={classes.resultWrapper}>
          {!!success_text && (
            <Flex alignItems="center" classes={{ root: classes.row }} wrap="nowrap">
              <CheckCircleIcon />
              <span className={classes.message}>{success_text}</span>
            </Flex>
          )}
          {!!failed_text && (
            <Flex alignItems="center" classes={{ root: classes.row }} wrap="nowrap">
              <AlertRoundIcon />
              <span className={classes.message}>{failed_text}</span>
            </Flex>
          )}
          {!!(url && url_text) && (
            <Flex alignItems="center" classes={{ root: classes.row }} wrap="nowrap">
              <span className={classes.successLink} onClick={() => onClickLink(url ?? '')}>
                {url_text}
              </span>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Toast>
  );
};

export const BulkActionCompletedToast = withStyles(styles)(BulkActionCompletedToastComponent);
