import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    content: {
      gap: 8,
    },
    message: {
      color: '#515D70', //TODO replace after creating new palette
      fontWeight: 400,
    },
    resultWrapper: {
      gap: 8,
    },
    row: {
      gap: 8,
    },

    failedLink: {
      cursor: 'pointer',
      color: '#101828', //TODO replace after creating new palette
      fontWeight: 500,
      textDecorationLine: 'underline',
    },
    successLink: {
      cursor: 'pointer',
      fontWeight: 500,
      textDecorationLine: 'underline',
    },
  });
