import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) => {
  const commonFullSizeStyles = {
    height: '100%',
    width: '100%',
  };

  return createStyles({
    root: {
      display: 'inline-block',
      position: 'relative',
      width: 75,
      height: 75,

      '&:hover': {
        '& $proBadge': {
          opacity: 0.2,
        },
      },
    },
    tooltip: {},
    avatar: {
      ...commonFullSizeStyles,
      marginBottom: spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: `1px solid ${colors.grey.lightish}`,
    },
    noImage: {
      ...commonFullSizeStyles,
      marginBottom: spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: `1px solid ${colors.grey.lightish}`,
    },
    noImageInitials: {
      ...commonFullSizeStyles,
      userSelect: 'none',
      borderRadius: '50%',
      fontSize: 26,
    },
    name: {
      fontSize: 17,
      fontWeight: 600,
    },
    proBadge: {
      bottom: -4,
      boxShadow: '0 -2px 3px 0 rgb(0 18 52 / 20%)',
      left: '50%',
      opacity: 1,
      position: 'absolute',
      transform: 'translateX(-50%)',
      transition: 'opacity 0.2s ease-in-out',
      userSelect: 'none',
    },
  });
};
