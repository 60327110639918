import { FC } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Link } from '@shared/components/link';
import ROUTES from '@shared/constants/routes';
import { ZapIcon } from '@shared/icons/zap';

import { styles } from './SideNavigationExploreUpsellItem.styles';

export type SideNavigationExploreUpsellItemProps = WithStyles<typeof styles>;

const SideNavigationExploreUpsellItemComponent: FC<SideNavigationExploreUpsellItemProps> = ({ classes }) => {
  return (
    <Link classes={{ root: classes.root }} to={`${ROUTES.upgradeToPro.root}`}>
      <span className={classes.title}>{t('upgrade_to_pro_1')}</span>
      <span className={classes.badge}>
        <ZapIcon classes={{ root: classes.icon }} />
        {t('explore').toUpperCase()}
      </span>
    </Link>
  );
};

export const SideNavigationExploreUpsellItem = withStyles(styles)(SideNavigationExploreUpsellItemComponent);
