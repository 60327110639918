import { AxiosError } from 'axios';
import i18next from 'i18next';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configQueryKeys, useConfigAgencyFetchQuery, useConfigLandlordFetchQuery } from '@apis/configApi';
import { queryClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { RootState } from '@redux/reducers/rootReducer';
import { ConfigErrorResponse } from '@shared/apis/configApi.types';
import { Loading } from '@shared/components/loading';
import { showNotification } from '@shared/components/notification';
import {
  ConfigAgency,
  ConfigAgencyConstants,
  ConfigCore,
  ConfigLandlord,
  ConfigLandlordConstants,
} from '@shared/models/config';
import { getDefaultError } from '@shared/utils/common';

export enum AppId {
  Agency,
  Landlord,
}

type Configs = ConfigAgency | ConfigLandlord;
type Constants = ConfigAgencyConstants | ConfigLandlordConstants;

export interface ConfigContextValue {
  appId: AppId;
  config: ConfigCore;
  constants: Constants;
  error: string;
  sideNavCollapsed: boolean;
  setSideNavCollapsed: UseStateSetter<boolean>;
}

export const ConfigContext = createContext<ConfigContextValue>({} as ConfigContextValue);

export const useConfigAll = () => useContext(ConfigContext);

export const useConfig = () => useContext(ConfigContext).config;

export const useAppUrl = () => {
  const { appId, config } = useConfigAll();

  switch (appId) {
    case AppId.Agency:
      return { appUrl: config.appUrl };
    case AppId.Landlord:
      return { appUrl: config.landlordAppUrl };
  }
};

// Strict agency constants
export const useAgencyConstants = () => {
  return { constants: useContext(ConfigContext).constants as ConfigAgencyConstants };
};

// Strict landlord constants
export const useLandlordConstants = () => {
  return { constants: useContext(ConfigContext).constants as ConfigLandlordConstants };
};

export interface ConfigProviderProps {
  appId: AppId;
}

export const ConfigProvider: FC<ConfigProviderProps> = ({ appId, children }) => {
  const dispatch = useDispatch();

  // Legacy stuff
  const reduxUser = useSelector((state: RootState) => state.config.user);

  const [sideNavCollapsed, setSideNavCollapsed] = useState(false);

  const successHandler = useCallback((config: Configs) => {
    window.AS = config;

    i18next.addResources('en', 'core', config.constants.localisation.translations);

    dispatch(configActions.setUser(config.user));
  }, []);

  const errorHandler = useCallback((error: AxiosError<ConfigErrorResponse>) => {
    if (error.response?.status === 403 && error.response?.data) {
      window.location.href = error.response.data.redirect_url;
    } else {
      showNotification(getDefaultError(), 'error');
    }
  }, []);

  const configAgencyQuery = useConfigAgencyFetchQuery({
    enabled: appId === AppId.Agency,
    onSuccess: successHandler,
    onError: errorHandler,
  });

  const configLandlordQuery = useConfigLandlordFetchQuery({
    enabled: appId === AppId.Landlord,
    onSuccess: successHandler,
    onError: errorHandler,
  });

  useEffect(() => {
    if (!reduxUser) {
      return;
    }

    if (appId === AppId.Agency && configAgencyQuery.isSuccess) {
      queryClient.setQueryData(configQueryKeys.showAgency().queryKey, { ...configAgencyQuery.data, user: reduxUser });
    }
    if (appId === AppId.Landlord && configLandlordQuery.isSuccess) {
      queryClient.setQueryData(configQueryKeys.showLandlord().queryKey, {
        ...configLandlordQuery.data,
        user: reduxUser,
      });
    }
  }, [appId, reduxUser]);

  const contextValue = useMemo(() => {
    let configs = {} as Configs;
    if (appId === AppId.Agency && configAgencyQuery.isSuccess) {
      configs = configAgencyQuery.data;
    } else if (appId === AppId.Landlord && configLandlordQuery.isSuccess) {
      configs = configLandlordQuery.data;
    }

    let errorMessage = '';
    if (appId === AppId.Agency && configAgencyQuery.isError) {
      errorMessage = configAgencyQuery.error?.message;
    } else if (appId === AppId.Landlord && configLandlordQuery.isError) {
      errorMessage = configLandlordQuery.error?.message;
    }

    const { constants, ...config } = configs;

    return {
      appId,
      config,
      constants,
      error: errorMessage,
      sideNavCollapsed,
      setSideNavCollapsed,
    };
  }, [
    appId,
    configAgencyQuery.data,
    configAgencyQuery.error,
    configAgencyQuery.isError,
    configAgencyQuery.isSuccess,
    configLandlordQuery.data,
    configLandlordQuery.error,
    configLandlordQuery.isError,
    configLandlordQuery.isSuccess,
    sideNavCollapsed,
  ]);

  if (configAgencyQuery.isFetching || configLandlordQuery.isFetching) {
    return <Loading absolute />;
  }

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
};
