const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/disposals',

  organisation: {
    disposals: {
      root: '/disposals',
      manage: {
        summary: '/summary',
        viewings: '/viewings',
        //viewing_insights: '/viewing-insights', //TODO find out if this page is required
        interest_schedule: '/interest-schedule',
        insights: '/insights',
      },
    },
    viewings: {
      root: '/viewings',
    },
  },
  marketplace: {
    marketplace_members: '/marketplace-members',
    marketplace_events: '/marketplace-events',
  },
};

export default ROUTES;
