import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './ProBadge.styles';

export type ProBadgeProps = WithStyles<typeof styles>;

const ProBadgeComponent: React.FC<ProBadgeProps> = ({ classes }) => {
  return <div className={classes.root}>pro</div>;
};

export const ProBadge = withStyles(styles)(ProBadgeComponent);
