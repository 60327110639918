import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ConfigCore } from '@shared/models/config';

import { styles } from './LogoImage.styles';

export interface LogoImageProps extends WithStyles<typeof styles> {
  appLogos: ConfigCore['appLogos'];
  onClick?: () => void;
}

const LogoImageComponent: FC<LogoImageProps> = ({ classes, appLogos, onClick }) => {
  return (
    <img
      alt={appLogos.alt}
      className={classes.root}
      src={appLogos.large_images[0]}
      srcSet={appLogos.large_images.length > 1 ? appLogos.large_images.join(', ') : undefined}
      title={appLogos.title || undefined}
      onClick={onClick}
    />
  );
};

export const LogoImage = withStyles(styles)(LogoImageComponent);
