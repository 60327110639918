import { Fade, Snackbar } from '@material-ui/core';
import { AlertProps, Color, Alert as MuiAlert } from '@material-ui/lab';

export interface NotificationProps {
  autoHideDuration?: number | null;
  type?: Color;
  className?: string;
}

interface NotificationState {
  message: React.ReactNode;
  type?: Color;
  notificationProps: Partial<NotificationProps>;
  open: boolean;
}

const defaultProps = {
  autoHideDuration: 5000,
  className: '',
};

let showNotificationFn: (
  message: React.ReactNode,
  type?: Color,
  notificationProps?: Partial<NotificationProps>
) => void;
let hideNotificationFn: () => void;
let isOpenNotificationFn: () => boolean;

export function showNotification(
  message: React.ReactNode,
  type?: Color,
  notificationProps?: Partial<NotificationProps>
) {
  showNotificationFn(message, type, notificationProps);
}

export function hideNotification() {
  hideNotificationFn();
}

export function isOpenNotification(): boolean {
  return isOpenNotificationFn();
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NotificationComponent extends React.Component<NotificationProps, NotificationState> {
  readonly state = {
    message: '',
    type: 'info' as Color,
    notificationProps: defaultProps,
    open: false,
  };

  componentDidMount() {
    showNotificationFn = this.open;
    hideNotificationFn = this.close;
    isOpenNotificationFn = this.isOpenNotification;
  }

  render() {
    const { message, type, notificationProps, open } = this.state;
    const { autoHideDuration } = notificationProps;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={autoHideDuration}
        open={open}
        TransitionComponent={Fade}
        onClose={this.close}
      >
        <Alert onClose={this.close} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  private isOpenNotification = () => this.state.open;

  private open = (message: React.ReactNode, type = 'info' as Color, notificationProps?: Partial<NotificationProps>) => {
    this.setState({
      message,
      type,
      notificationProps: {
        ...defaultProps,
        ...notificationProps,
      },
      open: true,
    });
  };

  private close = () => {
    this.setState({ notificationProps: defaultProps, open: false });
  };
}

export const Notification = NotificationComponent;
