import Echo, { Channel } from 'laravel-echo';
import Pusher from 'pusher-js';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getBaseUrl } from '@core/base-url';
import { useAgencyConstants, useConfig } from '@core/contexts/ConfigContext';
import { useStorageListeners } from '@core/hooks/localStorage';
import {
  PrivateEnvUserEventKey,
  PrivateSocietyEventKey,
  PrivateUserEventKey,
  PusherEventKey,
  PusherEventPayload,
  PusherNotificationKey,
  PusherNotificationPayload,
} from '@shared/types/common/events';

import {
  addOrUpdateToast,
  echoAuthorizer,
  getEventToast,
  getNotificationToast,
  openToastLink,
} from './PusherContext.utils';

// Expose Pusher on window, so Echo can access it
window.Pusher = Pusher;

type DismissedToastsValue = string[] | null;

export interface ToastItem {
  content: ReactNode;
  key: string;
  replaceKey?: string;
  time: number;
}

const DISMISSED_TOASTS_KEY = 'dismissedToasts';

export type EchoListenKey<TK extends PusherEventKey> = `.${TK}`;

export abstract class PusherCustomChannel extends Channel {
  abstract listen<TK extends PusherEventKey>(
    event: EchoListenKey<TK>,
    callback: (payload: PusherEventPayload[TK]) => any
  ): Channel;
  abstract stopListening<TK extends PusherEventKey>(event: EchoListenKey<TK>, callback?: () => any): Channel;
  abstract notification<TK extends PusherNotificationKey>(
    callback: (payload: PusherNotificationPayload[TK]) => any
  ): Channel;
}

const channelListen = <TK extends PusherEventKey>(
  channel: PusherCustomChannel,
  event: TK,
  callback: (payload: PusherEventPayload[TK]) => any
) => channel.listen<TK>(`.${event}`, callback);

const channelStopListening = <TK extends PusherEventKey>(channel: PusherCustomChannel, event: TK) =>
  channel.stopListening<TK>(`.${event}`);

export interface PusherContextValue {
  toasts: ToastItem[];
  addEventToast: (event: PusherEventKey, suppliedPayload: ValueOf<PusherEventPayload>) => void;
  addNotificationToast: (event: PusherNotificationKey, suppliedPayload: ValueOf<PusherNotificationPayload>) => void;
  channelListen: typeof channelListen;
  channelStopListening: typeof channelStopListening;
  getPrivateChannel: (channelName: string) => PusherCustomChannel;
}

export const PusherContext = createContext<PusherContextValue>({} as PusherContextValue);

export const usePusher = () => {
  return useContext(PusherContext);
};

export const PusherProvider: FC = ({ children }) => {
  const { pusherKey, user } = useConfig();
  const { constants } = useAgencyConstants();
  const storageListeners = useStorageListeners();

  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const dismissToast = (toastKey: string) => {
    // console.log('dismissToast:', toastKey);
    storageListeners.set<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (prev) => {
      if (prev) {
        return [...prev, toastKey];
      } else {
        return [toastKey];
      }
    });
  };

  const removeToast = (toastKey: string) => {
    // console.log('removeToast:', toastKey);
    dismissToast(toastKey);
    setToasts((_prev) => _prev.filter((toast) => toast.key !== toastKey));
  };

  const echoInstance = useMemo(
    () =>
      new Echo({
        broadcaster: 'pusher',
        cluster: 'eu',
        encrypted: true,
        key: pusherKey,
        wsHost: getBaseUrl(),
        authorizer: echoAuthorizer,
      }),
    [pusherKey]
  );

  const isDismissed = (toast: ToastItem) => {
    return storageListeners.get<DismissedToastsValue>(DISMISSED_TOASTS_KEY)?.includes(toast.key);
  };

  const addEventToast = useCallback(
    (event: PusherEventKey, suppliedPayload: ValueOf<PusherEventPayload>) => {
      // console.log(`Pusher event: ${event}`, suppliedPayload);
      const toast = getEventToast(event, suppliedPayload, user, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addEventToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [user]
  );

  const addNotificationToast = useCallback(
    (event: PusherNotificationKey, suppliedPayload: ValueOf<PusherNotificationPayload>) => {
      const toast = getNotificationToast(event, suppliedPayload, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addNotificationToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [user]
  );

  useEffect(() => {
    // User event listeners
    const userChannel: PusherCustomChannel = echoInstance.private(`${constants.APP_ENV}-App.User.${user.id}`);
    userChannel.error((error: any) => console.error('User channel error:', error));
    channelListen(userChannel, PrivateEnvUserEventKey.SocietyMatchMessageAdded, (data) => {
      addEventToast(PrivateEnvUserEventKey.SocietyMatchMessageAdded, data);
    });

    // Society event listeners
    const societyChannels: PusherCustomChannel[] = user.societies.map((society) => {
      return echoInstance.private('society-' + society.id + '-pulse');
    });
    societyChannels.forEach((channel, index) => {
      channel.error((error: any) => console.error(`Society ${user.societies[index]?.id} channel error:`, error));

      if (user.notify_settings.acquisitions.where.desktop) {
        channelListen(channel, PrivateSocietyEventKey.AcquisitionPublishedToSociety, (data) => {
          addEventToast(PrivateSocietyEventKey.AcquisitionPublishedToSociety, data);
        });
      }

      if (user.notify_settings.disposals.where.desktop) {
        channelListen(channel, PrivateSocietyEventKey.LettingPublishedToSociety, (data) => {
          addEventToast(PrivateSocietyEventKey.LettingPublishedToSociety, data);
        });
      }
    });

    // Private user event listeners
    const privateUserChannel: PusherCustomChannel = echoInstance.private(`App.User.${user.id}`);
    privateUserChannel.error((error: any) => console.error('Private channel error:', error));
    privateUserChannel.notification((data) => addNotificationToast(data.type, data));
    channelListen(privateUserChannel, PrivateUserEventKey.BulkActionProgress, (data) => {
      addEventToast(PrivateUserEventKey.BulkActionProgress, data);
    });
    channelListen(privateUserChannel, PrivateUserEventKey.BulkActionCompleted, (data) => {
      addEventToast(PrivateUserEventKey.BulkActionCompleted, data);
    });

    return () => {
      // Clear user event listeners
      channelStopListening(privateUserChannel, PrivateUserEventKey.BulkActionProgress);
      channelStopListening(privateUserChannel, PrivateUserEventKey.BulkActionCompleted);
      channelStopListening(userChannel, PrivateEnvUserEventKey.SocietyMatchMessageAdded);

      // Clear society event listeners
      societyChannels.forEach((channel) => {
        if (user.notify_settings.acquisitions.where.desktop) {
          channelStopListening(channel, PrivateSocietyEventKey.AcquisitionPublishedToSociety);
        }
        if (user.notify_settings.disposals.where.desktop) {
          channelStopListening(channel, PrivateSocietyEventKey.LettingPublishedToSociety);
        }
      });
    };
  }, [constants, echoInstance, user]);

  // Ensure the toasts are cleared when the user dismisses a toast in another tab
  useEffect(() => {
    storageListeners.listen<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (dismissedToasts) => {
      // console.log('dismissedToasts:', dismissedToasts);
      if (dismissedToasts) {
        setToasts((prev) => prev.filter((toast) => !dismissedToasts.includes(toast.key)));
      }
    });
  }, []);

  // Ensure the toasts are automatically dismissed
  useEffect(() => {
    const interval = setInterval(() => {
      setToasts((prev) => {
        const newToasts = prev.filter((toast) => {
          if (Date.now() - toast.time > 10000) {
            dismissToast(toast.key);
            return false;
          }
          return true;
        });

        return newToasts.length !== prev.length ? newToasts : prev;
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contextValue = useMemo(() => {
    const result: PusherContextValue = {
      toasts: toasts,
      addEventToast: addEventToast,
      addNotificationToast: addNotificationToast,
      channelListen: channelListen,
      channelStopListening: channelStopListening,
      getPrivateChannel: (channelName) => echoInstance.private(channelName),
    };
    return result;
  }, [addEventToast, addNotificationToast, echoInstance, toasts]);

  return <PusherContext.Provider value={contextValue}>{children}</PusherContext.Provider>;
};
