import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { IntercomLink } from '@shared/components/iintercom-link';

import { styles } from './SideNavigationAction.styles';

export interface SideNavigationActionProps extends WithStyles<typeof styles> {
  label: string;
  linkId: string;
  startIcon: ReactNode;
}
const SideNavigationActionComponent: FC<SideNavigationActionProps> = ({ classes, label, linkId, startIcon }) => {
  return (
    <IntercomLink classes={{ root: classes.root }} linkId={linkId}>
      <>
        <span className={classes.icon}>{startIcon}</span>
        <span className={classes.actionLabel}>{label}</span>
      </>
    </IntercomLink>
  );
};
export const SideNavigationAction = withStyles(styles)(SideNavigationActionComponent);
