import { Link } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { LogoImage } from '@shared/components/side-navigation/components/logo/logo-image';
import { ConfigCore } from '@shared/models/config';

import { styles } from './RxNavigationLogo.styles';

export interface RxNavigationLogoProps extends WithStyles<typeof styles> {
  appLogos: ConfigCore['appLogos'];
  katoUrl: string;
  url: string;
}

const RxNavigationLogoComponent: FC<RxNavigationLogoProps> = ({ classes, appLogos, katoUrl, url }) => {
  return (
    <Link classes={{ root: classes.root }} href={url}>
      <LogoImage appLogos={appLogos} classes={{ root: classes.logo }} />
      <span className={classes.poweredBy}>
        Powered by
        <Link href={katoUrl} classes={{ root: classes.poweredByLink }} target="_blank">
          Kato
        </Link>
      </span>
    </Link>
  );
};

export const RxNavigationLogo = withStyles(styles)(RxNavigationLogoComponent);
