import { Link } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { AgentSocietyLogoIcon } from '@shared/icons/agent-society-logo';
import { AgentSocietyTextIcon } from '@shared/icons/agent-society-text';

import { styles } from './AgentSocietyNavigationLogo.styles';

export interface AgentSocietyNavigationLogoProps extends WithStyles<typeof styles> {
  katoUrl: string;
  url: string;
}

const AgentSocietyNavigationLogoComponent: FC<AgentSocietyNavigationLogoProps> = ({ classes, katoUrl, url }) => {
  return (
    <Link classes={{ root: classes.root }} href={url}>
      <AgentSocietyLogoIcon classes={{ root: classes.icon }} />
      <Flex direction="column">
        <AgentSocietyTextIcon classes={{ root: classes.textIcon }} />
        <span className={classes.poweredBy}>
          Powered by
          <Link href={katoUrl} classes={{ root: classes.poweredByLink }} target="_blank">
            Kato
          </Link>
        </span>
      </Flex>
    </Link>
  );
};

export const AgentSocietyNavigationLogo = withStyles(styles)(AgentSocietyNavigationLogoComponent);
