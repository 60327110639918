import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: colors.kato.navy[950],
      borderRadius: 17,
      color: colors.kato.solid.white,
      display: 'inline-block',
      fontSize: 11,
      lineHeight: 1,
      padding: '3px 5px 2px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  });
